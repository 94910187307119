<template>
  <div id="app">
    <Navbar/> 
    <div class="section"><router-view/></div>
    <Footer/>
    <Install/>
    <Contact/>
    <Log/>
    <LoginModal />
    <LogoutModal />
    <RegisterModal />
    <RestoreModal />
    <!-- <AccountModal />
    <BillingModal /> -->
  </div>
</template>

<script>
  import { mapState } from "vuex";

  import Navbar from '@/components/Navbar.vue';
  import Footer from '@/components/Footer.vue';
  import Install from '@/components/Install.vue';
  import Contact from '@/components/Contact.vue';
  import Log from '@/components/Log.vue';
  import LoginModal from '@/components/accountModals/LoginModal.vue';
  import LogoutModal from '@/components/accountModals/LogoutModal.vue';
  import RegisterModal from "@/components/accountModals/RegisterModal.vue";
  import RestoreModal from "@/components/accountModals/RestoreModal.vue";
  // import AccountModal from "@/components/accountModals/AccountModal.vue";
  // import BillingModal from "@/components/accountModals/BillingModal.vue";

  export default {
    components: {
      Navbar,
      Footer,
      Install,
      Contact,
      Log,
      LoginModal,
      LogoutModal,
      RegisterModal,
      RestoreModal,
      //AccountModal,
      //BillingModal,
    },
    computed: {
      ...mapState(["user"]),
    },
    data(){
      return{}
    },
    mounted() {
      //console.log("test vars", this.$store.state.modals.sidebar);
      console.log("WEBSITE MOUNTED!");
      console.log("Begin User Check!");
      if (!this.$store.state.user.isLogged) {
        this.checkLogged();
      }
    },
    methods: {
      checkLogged() {
      if (localStorage.grtoken) {
        console.log("Validating Token..");
        //begin post --------------------------------------------
        this.axios
          .post("users/validate.php", {
            token: localStorage.grtoken,
            action: "validate",
          })
          .then((response) => {
            console.log("FASTRESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("validateUser", response.data);
              if(response.data.jwt){
                localStorage.grtoken = response.data.jwt;
                //console.log("has jwt");
              }
              console.log("Logged!");            
            }else{
              console.log("Did not pass validation!");
              this.$store.commit("logoutUser");
              this.showLogin();
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
        this.$store.commit("clearUserData");
      }
    },
    },
  }
</script>

<style lang="scss">
//@import 'static/fontawesome/css/all.min.css';
@import "static/synthosm/style.css";
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #0278E8; //#00bb8f; //00D1B2
$primary-invert: findColorInvert($primary);
$mydark: #181d29; //#00bb8f; //00D1B2
$transparent: rgba(0, 0, 0, 0);
//
//theme mod
$body-background-color: #000;
$loading-background: #000;
$footer-background-color: #000;

//modal
//$modal-background-background-color: #000;
$modal-card-head-background-color:#24242e;
$modal-card-head-border-bottom:rgb(66, 66, 66);
$modal-card-foot-border-top:rgb(102, 102, 102);
$modal-card-body-background-color:#24242e;

$modal-card-title-color:rgb(245, 245, 245);
$text:rgb(230, 230, 230);
$title-color:rgb(190, 190, 190);
$link:#5b72d6;
$button-text-color:#0278E8;
$input-color:#fff;
$input-background-color:rgb(36, 36, 36);
$input-border-color:rgb(20, 20, 20);

$label-color: #fff;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "mydark": ($mydark, $white),
    "transparent": ($transparent, $white),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "static/steps.sass";


a{
  color:#718bff;
};
a:hover{
  color:#c8d1f8;
};
.message-body{
  color:#000;
}
.navbar-menu {
  background-color: #0a0a0a;
}
.navbar-dropdown {
    background-color: #24242e;
}

.icon.is-large {
    font-size: 3rem;
}

.footer {
  padding: 2rem 1.5rem 2rem;
}

.modal-width{
  min-width: 450px;
  width: auto;
}

</style>

