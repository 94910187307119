<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Restore Access</p>
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <b-field v-if="form.visible" label="Email" :type="form.email.status" :message="form.email.message">
            <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
          </b-field>
          <b-field class="m-t-md" v-if="form.visible" :label="this.form.human.label" :type="form.human.status"
            :message="form.human.message" horizontal>
            <b-input type="text" v-model="form.human.data" placeholder="Your answer" required></b-input>
          </b-field>

          <b-message :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <button class="button is-primary" type="button" @click="openLogin()">
              Sign in Now
            </button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>
          <button class="button is-primary" @click="onSubmit()">
            Restore Access
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "RestoreModal",
  data() {
    return {
      tempToken: "",
      isLoading: false,
      countErrors: 0,
      form: {
        email: {
          data: "",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },
    generateToken() {
      //begin post --------------------------------------------
      this.axios
        .post("users/temptoken.php", {
          action: "getToken"
        })
        .then(response => {

          console.log(response.data);
          if (response.data.success) {
            this.tempToken = response.data.token;
          } else {
            this.tempToken = "";
          }
        })
        .catch(error => {
          this.tempToken = "";
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);

      if (
        this.emailCheck("email") &
        this.humanCheck()
      ) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/restore.php", {
            action: "restore",
            email: this.form.email.data,
            token: this.tempToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    humanCheck() {
      if (this.form.human.data == this.form.human.summ) {
        this.form.human.status = "is-success";
        this.form.human.message = "Human, eh?";
        return true;
      } else {
        this.form.human.status = "is-danger";
        this.form.human.message = "You did not pass the human test.";
        return false;
      }
    },
    openLogin() {
      this.$store.commit("showModal", "login");
      this.closeModal();
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.$store.commit("hideModal", "restore");
    },
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.restore;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "restore");
        return false;
      }
    }
  },
  watch: {
    isActive: function () {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.human.data = null;
      this.form.human.status = "";
      this.form.human.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.form.visible = true;
      this.generateCapcha();
      this.generateToken();
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
