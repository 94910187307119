<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div
        class="modal-card"
        style="width: auto"
        @keyup.enter.exact="onSubmit()"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Logout</p>
        </header>
        <section class="modal-card-body">
          Are you sure you want to Log Out?
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal()">
            Cancel
          </button>
          <button class="button is-primary" @click="doLogout()" :loading="isLoading">Log Out</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
export default {
  name: "LogoutModal",
  mixins:[globalMixin],
  data() {
    return {
      keep: true,
      isLoading: false,
    };
  },
  methods: {
    doLogout() {
        this.$store.commit("logoutUser");
        // push to home page
        this.$router.push({ name: "home" });
        this.closeModal();
    },
    closeModal() {
      this.$store.commit("hideModal", "logout");
    },
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.logout;
      },
      // setter
      set: function() {
        this.$store.commit("modals/hideModal", "logout");
        return false;
      }
    }
  },
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between; /* flex-end; */
}
</style>
