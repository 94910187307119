<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px; height:60vh">
        <header class="modal-card-head">
          <p class="modal-card-title">ChangeLog</p>
        </header>
        <section class="modal-card-body has-text-light">

        <div class="log-item">
          <p class="log-date">May 1, 2023</p>
          <p class="log-title">Versions 2.0.0</p>
          <p class="log-text">
            <ul>
              <li>Interface Updates</li>
              <li>New Widgets: Youtube Lists, Code Notes, Ambiance, File Transfer, iFrame</li>
              <li>Sared Pages</li>
            </ul>
          </p>
        </div>

          
        <div class="log-item">
          <p class="log-date">April 7, 2023</p>
          <p class="log-title">Versions 1.0.4</p>
          <p class="log-text">
            <ul>
              <li>Minor Fixes</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">February 18, 2021</p>
          <p class="log-title">Versions 1.0.3</p>
          <p class="log-text">
            <ul>
              <li>Interface Updates</li>
              <li>Bug Fixes</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">January 30, 2021</p>
          <p class="log-title">Versions 1.0.2</p>
          <p class="log-text">
            <ul>
              <li>Bug Fixes</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">January 15, 2021</p>
          <p class="log-title">Version 1.0.1</p>
          <p class="log-text">
            <ul>
              <li>New Widgets: Link Lists, RSS Feed</li>
              <li>Support for Fitefox</li>
              <li>Support for Edge</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">December 30, 2020</p>
          <p class="log-title">Version 0.1.7</p>
          <p class="log-text">
            <ul>
              <li>Grid Pages</li>
              <li>LightS Off Button</li>
              <li>Bug Fixes</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">November 15, 2020</p>
          <p class="log-title">Version 0.1.6</p>
          <p class="log-text">
            <ul>
              <li>New Widget: Calendar</li>
              <li>Editable To-Do Items</li>
            </ul>
          </p>
        </div>

        <div class="log-item">
          <p class="log-date">May 6, 2020</p>
          <p class="log-title">Version 0.1.5</p>
          <p class="log-text">Minor updates and Bug fixes</p>
        </div>

        <div class="log-item">
          <p class="log-date">May 6, 2020</p>
          <p class="log-title">Version 0.1.3</p>
          <p class="log-text">New Widgets: Weather, Calculator</p>
        </div>

        <div class="log-item">
          <p class="log-date">February 26, 2020</p>
          <p class="log-title">Version 0.1.2</p>
          <p class="log-text">Minor Updates</p>
        </div>
          
          
        <div class="log-item">
          <p class="log-date">January 20, 2020</p>
          <p class="log-title">Version 0.1.0</p>
          <p class="log-text">This is our first Alpha release</p>
        </div>


        </section>
        <footer class="modal-card-foot">
          <b-button type="is-dark" @click="close()">
            Close
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "Log",
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$store.commit('hideModal', 'changelog');
    },
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.changelog;
      },
      // setter
      set: function() {
        this.$store.commit('hideModal', 'changelog');
        return false;
      }
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center; /* flex-end; */
}
.log-title{
  font-size: 1.3rem;
}
.log-date{
  font-size: 0.8rem;
  color: rgb(95, 95, 95);
}
.log-item{
  border-bottom: 1px solid rgb(92, 92, 92);
  padding-bottom: 10px;
  padding-top: 40px;
}
</style>
