<template>
  <b-navbar type="is-black" wrapper-class="container" fixed-top>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="/img/gridsup_logo.svg" width="150px" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <!-- <b-navbar-item v-scroll-to="{ el: '#overview', duration: 500, offset: -49 }">About</b-navbar-item>
      <b-navbar-item v-scroll-to="{ el: '#testimonials', duration: 500, offset: -49 }">Testimonials</b-navbar-item> -->
      <b-navbar-item tag="router-link" to="/#overview">About</b-navbar-item>
      <b-navbar-item tag="router-link" to="/#testimonials">Testimonials</b-navbar-item>
      <b-navbar-item tag="router-link" to="/#faq">FAQ</b-navbar-item>
      <b-navbar-item @click="showModal('changelog')">Changelog</b-navbar-item>
      <b-navbar-item @click="showModal('contact')">Contact</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/pricing' }">Pricing</b-navbar-item>
    </template>

    <!-- <template slot="end" v-if="currentRouteName=='Home'">
      <b-navbar-item v-scroll-to="{ el: '#overview', duration: 500, offset: -49 }">About</b-navbar-item>
      <b-navbar-item v-scroll-to="{ el: '#testimonials', duration: 500, offset: -49 }">Testimonials</b-navbar-item>
      <b-navbar-item v-scroll-to="{ el: '#faq', duration: 500, offset: -49 }">FAQ</b-navbar-item>
      <b-navbar-item @click="showModal('changelog')">Changelog</b-navbar-item>
      <b-navbar-item @click="showModal('contact')">Contact</b-navbar-item>
      <b-navbar-item>
        <b-button type="is-primary" @click="openOnline()">Try</b-button>
        <b-button type="is-success" @click="showModal('install')">Install</b-button>
      </b-navbar-item>
    </template> -->
    <template slot="end">
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button type="is-primary" @click="openOnline()">Try</b-button>
          <b-button type="is-success" @click="showModal('install')">Install</b-button>
        </div>
      </b-navbar-item>
      <!-- <b-navbar-item>
        <b-icon icon="user"></b-icon>
      </b-navbar-item> -->

      <b-navbar-item v-if="!user.isLogged" @click="showModal('login')">Log In <b-icon class="ml-2" icon="arrow-right-to-bracket"></b-icon></b-navbar-item>
      <b-navbar-dropdown v-if="user.isLogged" label="Account">
        <b-navbar-item tag="router-link" to="/profile">
          Profile
        </b-navbar-item>
        <!-- <b-navbar-item href="#">
          Subscription
        </b-navbar-item> -->
        <b-navbar-item @click="showModal('logout')">
          Log Out <b-icon class="ml-2" icon="arrow-right-from-bracket"></b-icon>
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Navbar",
  methods: {
    showModal(modal) {
      console.log("showing modal");
      this.$store.commit("showModal", modal);
    },
    openOnline(){
      window.open("https://online.gridsup.com/", '_blank');
    },
  },
  computed: {
    ...mapState(["user"]),
    currentRouteName() {
        return this.$route.name;
    }
  }
};
</script>
<style>
.navbar-item img {
  max-height: unset !important;
}
</style>
