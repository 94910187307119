<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: auto" @keyup.enter.exact="onSubmit()">
        <header class="modal-card-head">
          <p class="modal-card-title">Login</p>
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <b-field label="Email" :type="form.email.status" :message="form.email.message">
            <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
          </b-field>

          <b-field label="Password" :type="form.pass.status" :message="form.pass.message">
            <b-input type="password" v-model="form.pass.data" password-reveal placeholder="Your password" required>
            </b-input>
          </b-field>

          <a @click="openRestore()" type="is-light">Forgot password?</a>

          <b-message :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="openRegister()">
            Create an Account?
          </button>
          <button class="button is-primary" @click="onSubmit()">Log In</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import confirmMixin from "@/mixins/confirmMixin";
export default {
  name: "LoginModal",
  mixins: [confirmMixin],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        email: {
          data: "",
          status: "",
          message: ""
        },
        pass: {
          data: "",
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("modals/showModal", false);

      if (this.emailCheck("email") & this.passCheck("pass")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/login.php", {
            email: this.form.email.data,
            pass: this.form.pass.data
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
             this.$store.commit("loginUser", response.data);
             // this.$store.dispatch("users/loginUser", response.data);
              // if(response.data.status==0){
              //   this.doConfirm();
              // }
              //this.$router.push({ path: "/category/all" });
              this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.pass.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        return true;
      }
    },
    passCheck() {
      if (this.form.pass.data === "") {
        this.form.pass.message = "Please enter a Password";
        this.form.pass.status = "is-danger";
        return false;
      } else {
        this.form.pass.message = null;
        this.form.pass.status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    humanCheck(e) {
      if (this.form[e]) {
        this.formvalidation[e] = true;
        return true;
      } else {
        this.formvalidation[e] = false;
        return false;
      }
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.$store.commit("hideModal", "login");
    },
    openRegister() {
      this.$store.commit("hideModal", "login");
      this.$store.commit("showModal", "register");
    },
    openRestore() {
      this.$store.commit("hideModal", "login");
      this.$store.commit("showModal", "restore");
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.login;
      },
      // setter
      set: function () {
        this.$store.commit("hideModal", "login");
        return false;
      }
    }
  },
  mounted() {
    if (localStorage.gremail) {
      this.form.email.data = localStorage.gremail;
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
