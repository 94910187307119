import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import VueScrollTo from "vue-scrollto";
//import LoadScript from "vue-plugin-load-script";

Vue.config.productionTip = false

Vue.prototype.$mySite = {
  title: "Gridsup.com",
  url: "https://gridsup.com",
  image: "https://gridsup.com/share/share.jpg",
  api: "https://gridsup.com/bestapi/"
};

axios.defaults.baseURL = "https://gridsup.com/bestapi/";

Vue.use(VueAxios, axios);
Vue.use(Buefy, {
  defaultIconPack: "synthosm",
  //defaultIconPack: "syntho",
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
//Vue.use(VueScrollTo);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
//Vue.use(LoadScript);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
