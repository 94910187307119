<template>
  <footer class="footer container">
    <div class="content has-text-centered">
      <div class="level"> 
        <div class="level-left">
          <div class="level-item">
            Gridsup by <a class="mx-2" href="https://syntho.dev">Syntho.dev</a> &copy; {{year}}
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
             <router-link class="mr-2" :to="{ path: '/terms' }">Terms</router-link> | 
             <router-link class="ml-2" :to="{ path: '/privacy' }">Privacy</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return{
      year: "1983"
    }
  },
  mounted(){
    let curDate = new Date();
    this.year = curDate.getFullYear();
    console.log(this.year);
  }
};
</script>
<style>
.navbar-item img {
  max-height: unset !important;
}
</style>
