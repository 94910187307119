<template>
  <section>
    <b-modal
      :active.sync="isActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 400px">
        <header class="modal-card-head">
          <p class="modal-card-title">Select Browser</p>
        </header>
        <section class="modal-card-body">
          <div class="buttons">
            <b-button type="is-light" size="is-medium" expanded outlined @click="goToURL('https://chrome.google.com/webstore/detail/gridsup/nginabbjhjjbmjcimkmhloleglognimo?hl=en')">
              <img class="mr-2" src="/img/logos/chrome.svg" width="20px" alt=""> Chrome
            </b-button>
            <b-button type="is-light" size="is-medium" expanded outlined @click="goToURL('https://addons.mozilla.org/en-US/firefox/addon/gridsup/')">
              <img class="mr-2" src="/img/logos/firefox.svg" width="20px" alt=""> Fitefox
            </b-button>
            <b-button type="is-light" size="is-medium" expanded outlined @click="goToURL('https://microsoftedge.microsoft.com/addons/detail/gridsup/emdaiheeichfdnpcahhedcdhfdmipggh')">
              <img class="mr-2" src="/img/logos/edge.svg" width="20px" alt=""> Edge
            </b-button>
            <b-button type="is-light" size="is-medium" expanded outlined @click="goToURL('https://chrome.google.com/webstore/detail/gridsup/nginabbjhjjbmjcimkmhloleglognimo?hl=en')">
              <img class="mr-2" src="/img/logos/brave.svg" width="20px" alt=""> Brave
            </b-button>
          </div>

        </section>
        <footer class="modal-card-foot">
          <b-button type="is-dark" @click="close()">
            Cancel
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: "Install",
  methods: {
    close() {
      this.$store.commit('hideModal', 'install');
    },
    goToURL(link){
      window.location.href = link;
    },
  },
  computed: {
    isActive: {
      get: function() {
        return this.$store.state.modals.install;
      },
      // setter
      set: function() {
        this.$store.commit('hideModal', 'install');
        return false;
      }
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: center;
}
</style>
