import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modals:{
      install: false,
      contact: false,
      changelog: false,
      login: false,
      register: false,
      restore: false,
      logout: false,
      account: false,
      billing: false,
    },
    user: {
      isLogged: false,
      name: null,
      email: null,
      status: null,
      id: null,
      token: null,
    },
    selectedPlan: null,
  },
  getters: {
    isUserEmail: (state) => {
      return state.user.email;
    }
  },
  mutations: {
    hideModal(state, payload) {
      state.modals[payload] = false;
    },
    showModal(state, payload) {
      state.modals[payload] = true;
    },
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
      //local
      localStorage.grtoken = payload.jwt;
      localStorage.gremail = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
      //local
      localStorage.removeItem("grtoken");
      localStorage.removeItem("gremail");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.grtoken;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
    },
    setStatus(state, payload) {
      localStorage.removeItem("grtoken");
      localStorage.grtoken = payload.jwt;
      state.user.token = payload.jwt
      state.user.status = payload.status;
    },
    clearUserData(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
    },
    setPlan(state, payload) {
      state.selectedPlan = payload;
      localStorage.removeItem("grplan");
      localStorage.grplan = payload;
    }
  },
  actions: {
  },
  modules: {
  }
});
