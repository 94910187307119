<template>
  <div class="home">
    <section class="hero is-transparent is-medium has-text-centered container">
      <div class="hero-body">
        <p class="title">
          All-in-one browser extension for Productivity
        </p>
        <p class="subtitle mt-3">
          Gridsup is a suite of tools made to enhance your workflow experience and boost your productivity.
        </p>
        <!-- showModal('install') -->
        <b-button @click="openOnline()" class="mt-5 mr-2" size="is-medium" type="is-primary">Try Online</b-button>
        <b-button @click="showModal('install')" class="mt-5" size="is-medium" type="is-success">Install for Free</b-button>
        <div class="mt-6">
          <a href="https://chrome.google.com/webstore/detail/gridsup/nginabbjhjjbmjcimkmhloleglognimo?hl=en"><img class="mr-5" src="img/logos/chrome.svg" width="50px" alt="Install Gridsup Chrome"></a>
          <a href="https://addons.mozilla.org/en-US/firefox/addon/gridsup/"><img class="mr-5" src="img/logos/firefox.svg" width="50px" alt="Install Gridsup Firefox"></a>
          <a href="https://microsoftedge.microsoft.com/addons/detail/gridsup/emdaiheeichfdnpcahhedcdhfdmipggh"><img class="mr-5" src="img/logos/edge.svg" width="50px" alt="Install Gridsup Edge"></a>         
          <a href="https://chrome.google.com/webstore/detail/gridsup/nginabbjhjjbmjcimkmhloleglognimo?hl=en"><img src="img/logos/brave.svg" width="50px" alt="Install Gridsup Brave"></a>
        </div>
      </div>
    </section>
    <section id="overview" class="section">
      <div class="container video-section">
      <div class="video-box">
        <iframe class="video" src="https://www.youtube.com/embed/IB8KvHPnxTY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      </div>
    </section>
    <section class="section is-medium widgets has-text-centered">
      <div class="container">
        <div class="title">
          Tools and Widgets
        </div>
        <div class="subtitle">
          Made to improve and ease your daily experience.
        </div>
        <div class="columns mt-6">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-first" icon="clipboard-list-check"></b-icon>
              <p class="box-title mt-2">To-Do List</p>
              <p class=" mt-2">The To-Do list to organize work and life</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-first" icon="note-sticky"></b-icon>
              <p class="box-title mt-2">Notes</p>
              <p class=" mt-2">Easy to use and always at your disposal</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-first" icon="calendar-alt"></b-icon>
              <p class="box-title mt-2">Calendar</p>
              <p class=" mt-2">Take notes on another level</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-second" icon="list-timeline"></b-icon>
              <p class="box-title mt-2">Links List</p>
              <p class=" mt-2">The easy way to store your project links</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-second" icon="cloud"></b-icon>
              <p class="box-title mt-2">Weather</p>
              <p class="mt-2">Forecast all around the world</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-second" icon="clock"></b-icon>
              <p class="box-title mt-2">Clock</p>
              <p class="mt-2">World clocks, time to go international</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-third" icon="link-simple"></b-icon>
              <p class="box-title mt-2">Links</p>
              <p class="mt-2">Customizable shortcuts for your websites</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-third" icon="square-rss"></b-icon>
              <p class="box-title mt-2">RSS Feed</p>
              <p class="mt-2">Stay informed with freshly updated news</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-third" icon="calculator"></b-icon>
              <p class="box-title mt-2">Calculator</p>
              <p class="mt-2">Well, you know what this is :)</p>
            </div>
          </div>
        </div>
        <div class="subtitle">
          and more...
        </div>
      </div>
    </section>

    <section id="testimonials" class="section is-medium testimonials has-text-centered">
      <div class="container">
        <div class="title">
          What People are saying
        </div>
        <div class="subtitle">
          People around the internet love using Gridsup.
        </div>
        <div class="columns mt-6">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="twitter"></b-icon>
              <p class="mt-2">Best Extension ever! Have been using it for several months. Couldn't do without it now.</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="firefox"></b-icon>
              <p class=" mt-2">Great page to keep your main websites handy. It's way better than searching through bookmarks!</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="chrome"></b-icon>
              <p class=" mt-2">Excellent add-on for bookmarks and speed dialing. Indeed a very handy tool!</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="firefox"></b-icon>
              <p class=" mt-2">Awesome app ever since it became available I've always used it on all my pc's it's the best at least it works for me. Its my number one go to home page and browser.</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="edge"></b-icon>
              <p class="mt-2">Absolutely love this, my favorite pages all in one place which makes it easy to perform tasks! Great job guys, I love it!</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="telegram"></b-icon>
              <p class="mt-2">This is a great application. I find it much simpler than organizing bookmarks.</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="chrome"></b-icon>
              <p class=" mt-2">Enjoy the app, easy, attractive and a pleasure to use. Simple, does what it says.</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="facebook"></b-icon>
              <p class="mt-2">It's an awesome software. Just love it.</p>
            </div>
          </div>
          <div class="column">
            <div class="box-black">
              <b-icon class="icon-white" icon="twitter"></b-icon>
              <p class="mt-2">Great organizational tool for teachers!</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq" class="section is-medium faq has-text-centered">
      <div class="container">
        <div class="title">
          Frequently Asked Questions
        </div>
        <div class="subtitle">
          You have questions, we have answers!
        </div>

        <div class="has-text-left has-text-white">
          <div class="columns mt-6">
            <div class="column is-one-third has-text-weight-bold">
              What browsers are supported by Gridsup?
            </div>
            <div class="column">
              Gridsup supports Firefox, Chrome and Chromium forks like Brave, Edge, etc.<br/>
              Please feel free to contact us if your browser is not listed or have any doubt regarding support.
            </div>
          </div>
          <div class="columns mt-6">
            <div class="column is-one-third has-text-weight-bold">
              Do you collect my personal data? 
            </div>
            <div class="column">
              By default the extension doesen't collect any personal data. All data that you provide is stored localy. In case that you create an account with Grisup we will store your login credentials and shared pages on our servers. If you use our Cloud Backup option your data will be encrypted before stored on our servers.
            </div>
          </div>
          <div class="columns mt-6">
            <div class="column is-one-third has-text-weight-bold">
              Why can't I create more pages?
            </div>
            <div class="column">
              You have to purchase a subscription or a licence key to remove this limitation. <router-link to="/pricing">Press here to subscribe</router-link>. There are no other limitations for Grids Up usage.
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    showModal(modal) {
      console.log("showing modal");
      this.$store.commit("showModal", modal);
    },
    openOnline(){
      window.open("https://online.gridsup.com/", '_blank');
    },
  },
  mounted() {
    //this.$scrollTo('#faq', 500);
  },
  watch: {
    $route(to, from) {
      if (to.hash === "#faq") {
        this.$scrollTo('#faq', 500);
      }
      if (to.hash === "#testimonials") {
        this.$scrollTo('#testimonials', 500);
      }
      if (to.hash === "#overview") {
        this.$scrollTo('#overview', 500);
      }
    }
  },
  metaInfo() {
    return {
      title: "Gridsup - All-in-one Browser Extension for Productivity",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "Gridsup is a suite of tools made to enhance your workflow experience and boost your productivity."
        },
        {
          property: "og:title",
          content: "Gridsup - All-in-one Browser Extension for Productivity",
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Gridsup is a suite of tools made to enhance your workflow experience and boost your productivity.",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: "https://gridsup.com/img/share.png",
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: "https://gridsup.com",
          vmid: "og:url"
        }
      ]
    };
  } //end meta
}
</script>
<style scoped>

.hero .title{
  /* text-transform: uppercase; */
  font-size: 3.2rem;
  font-weight: 900;
}

.hero .subtitle{
  color: rgb(173, 173, 173)!important;
  font-size: 1.5rem;
}

.widgets .title{
  /* text-transform: uppercase; */
  font-size: 2rem;
  font-weight: 900;
  color: #ffffff;
}

.widgets .subtitle{
  color: rgb(173, 173, 173)!important;
  font-size: 1.5rem;
}

.testimonials{
  background-color: #0b0c0c;
}

.testimonials .title{
  /* text-transform: uppercase; */
  font-size: 2rem;
  font-weight: 900;
  color: #ffffff;
}

.testimonials .subtitle{
  color: rgb(173, 173, 173)!important;
  font-size: 1.5rem;
}

.faq .title{
  /* text-transform: uppercase; */
  font-size: 2rem;
  font-weight: 900;
  color: #ffffff;
}

.faq .subtitle{
  color: rgb(173, 173, 173)!important;
  font-size: 1.5rem;
}

.video-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-box{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box-black {
  background-color: #1b1c1d;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #fff;
  display: block;
  padding: 2.25rem;
  margin: 0 20px 0 20px;
  text-align: left;
}

.box-blue {
  background-color: #0068cf3a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #fff;
  display: block;
  padding: 2.25rem;
  margin: 0 20px 0 20px;
  text-align: left;
}

.box-orange {
  background-color: #cf6e003a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #fff;
  display: block;
  padding: 2.25rem;
  margin: 0 20px 0 20px;
  text-align: left;
}

.box-red {
  background-color: #cf00003a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #fff;
  display: block;
  padding: 2.25rem;
  margin: 0 20px 0 20px;
  text-align: left;
}

.box-green {
  background-color: #00cf003a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: #fff;
  display: block;
  padding: 2.25rem;
  margin: 0 20px 0 20px;
  text-align: left;
}

.box-title {
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.icon-first{
  font-size: 3rem;
  color:#47F8CC;
}

.icon-second{
  font-size: 3rem;
  color:#25BADA;
}

.icon-third{
  font-size: 3rem;
  color:#0278E8;
}

.icon-white{
  font-size: 2rem;
  color:#ffffff;
}

</style>
