import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
 {
    path: '/terms',
    name: 'TermsView',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyView',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyView.vue')
  },
  {
    path: '/pricing',
    name: 'PricingView',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingView.vue')
  },
  {
    path: '/step-one',
    name: 'StepOneView',
    component: () => import(/* webpackChunkName: "stepone" */ '../views/StepOneView.vue')
  },
  {
    path: '/step-two',
    name: 'StepTwoView',
    component: () => import(/* webpackChunkName: "stepstwo" */ '../views/StepTwoView.vue')
  },
  {
    path: '/step-three',
    name: 'StepThreeView',
    component: () => import(/* webpackChunkName: "steps" */ '../views/StepThreeView.vue')
  },
  {
    path: '/license',
    name: 'LicenseView',
    component: () => import(/* webpackChunkName: "license" */ '../views/LicenseView.vue')
  },
  {
    path: '/appsumo/:key?',
    name: 'AppsumoView',
    component: () => import(/* webpackChunkName: "appsumo" */ '../views/AppsumoView.vue')
  },
  {
    path: '/restore',
    name: 'RestoreView',
    component: () => import(/* webpackChunkName: "restore" */ '../views/RestoreView.vue')
  },
  {
    path: '/thanks',
    name: 'ThanksView',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/ThanksView.vue')
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/license.html', redirect: '/license'    
  },
  {
    path: '/thanks.html', redirect: '/thanks'  
  },
  {
    path: '/terms.html', redirect: '/terms'  
  },
  {
    path: '/privacy.html', redirect: '/privacy'  
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }else{
      return {
        x: 0,
        y: 0
      };
    }
  }
});

export default router;
